import { Controller } from "@hotwired/stimulus";
import SlimSelect, { type Config } from "slim-select";

export default class extends Controller {
  declare slimselect: SlimSelect;

  connect(): void {
    this.setup();
  }

  disconnect(): void {
    this.cleanup();
  }

  private setup(): void {
    this.cleanup();
    if (!this.element) {
      return;
    }

    type HeadersMap = Record<string, string>;
    const _headers: HeadersMap = { "Content-Type": "application/json" };
    const _url = "/admin/due_diligence/assignment/apps/{QUERY}";
    const _ajax = (query: string) => {
      if (query === "" || query == null) {
        return [];
      }
      return new Promise((resolve, reject) => {
        const validateResponse = (response) => {
          if (!response.ok) {
            reject(new Error("No results found"));
          }
          return response.json();
        };

        // Ensure someone isn't trying a query with a string like `     a      `
        const cleanedQuery: string = query.trim();
        // Require at least 3 characters to do an ajax lookup
        if (cleanedQuery.length < 3) {
          reject(new Error("Search must be at least 2 characters"));
        }
        const queryUrl = _url.replace("{QUERY}", encodeURIComponent(cleanedQuery));
        fetch(queryUrl, { method: "GET", headers: _headers })
          .then(validateResponse)
          .then((data) => {
            // the shape of the data required for slimselect to convert to dropdown entries
            const results = data.map((result) => {
              return {
                text: result.name,
                value: result.id,
              };
            });
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    interface OptionsMap {
      select: Element;
      events: {
        search: <T>(query: T | string, callback: T | unknown) => Promise<unknown>;
      };
    }
    const options: OptionsMap = {
      select: this.element,
      events: {
        search: _ajax,
      },
    };
    this.slimselect = new SlimSelect(options as unknown as Config);
  }

  private cleanup(): void {
    if (!this.slimselect) {
      return;
    }
    this.slimselect.destroy();
    this.slimselect = undefined;
  }
}
