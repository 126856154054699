import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidden"];
  declare readonly hasHiddenTarget: boolean;
  declare readonly hiddenTarget: HTMLFormElement | null;

  toggle(e: Event): void {
    e.preventDefault();
    if (this.hasHiddenTarget) {
      this.hiddenTarget.classList.toggle("d-none");
    }
  }
}
