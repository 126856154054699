import "@hotwired/turbo-rails";

import "./channels"; // For ActionCable Channels
import "./controllers"; // For StimulusJS Controllers

// Entry point for the build script in your package.json
import "./src/plugins/jquery";
import * as bootstrap from "bootstrap";
import "./src/plugins/bootstrap_utils";
import "./src/plugins/highcharts";

// Load all DueDiligence Components
import "./src/due_diligence";
// So Turbo isn't enabled on all forms, links, etc, by default
// This allows a gradual transition of implementing of turboframes
Turbo.session.drive = false;
window.bootstrap = bootstrap;
