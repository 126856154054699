import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    display: { type: String, default: "false" },
  };

  declare displayValue: string | null;
  declare hasDisplayValue: boolean;

  static targets = ["form"];
  declare readonly hasFormTarget: boolean;
  declare readonly formTarget: Element | null;
  declare readonly formTargets: Element[];

  connect(): void {}

  toggle(): void {
    if (this.hasFormTarget) {
      this.toggleState();
    }
  }

  private toggleState(): void {
    if (this.hasDisplayValue && this.displayValue === "true") {
      // the form is displayed, hide it
      this.displayValue = "false";
      this.formTarget.classList.add("d-none");
    } else {
      // the form is hidden, display it
      this.displayValue = "true";
      this.formTarget.classList.remove("d-none");
    }
  }
}
