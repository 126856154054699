document.addEventListener("turbo:load", () => {
  // Enable Tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  // Enable popovers
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

  // Enable Collapse
  const collapseElementList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="collapse"]'),
  );
  collapseElementList.map((collapseEl) => new bootstrap.Collapse(collapseEl));
});
