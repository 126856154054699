import { Controller } from "@hotwired/stimulus";

// Connects to data_controller="progress-bar"
export default class extends Controller {
  static estimateBatchTargets = () => {
    const currentYear = new Date().getFullYear();
    const oldestYear = currentYear - 5;
    const estimateBatchTargets = ["carbonEstimateBatchT12", "energyEstimateBatchT12"];

    for (let i = oldestYear; i <= currentYear; i++) {
      estimateBatchTargets.push(`carbonEstimateBatch${i}`);
      estimateBatchTargets.push(`energyEstimateBatch${i}`);
    }
    return estimateBatchTargets;
  };

  static otherTargets = [
    "modelClass",
    "modelUuid",
    "buildingCandidateGeolocationProgress",
    "ordinanceLookupBatch",
    "certificationLookupBatch",
  ];

  static targets = [...this.estimateBatchTargets(), ...this.otherTargets];

  setup() {
    for (const t of this.constructor.targets) {
      const target = `${t}Target`;
      const hasMethod = `has${target[0].toUpperCase() + target.slice(1)}`;
      // Since not all targets (namely data set batches) are present for all asset level file uploads,
      // we need to check if the target exists
      if (this[hasMethod]) {
        const elements = this[target];
        if (elements) {
          const params = {
            model_class: elements.dataset.modelClass,
            model_uuid: elements.dataset.modelUuid,
          };
          this.batchSetUp(target);
          this.fetchProgressBarValues(target, params);
        }
      }
    }
  }

  fetchProgressBarValues(targetCollection, params) {
    const queryParams = new URLSearchParams(params);
    const currentUrl = window.location.pathname;
    const id = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    const fetchProgressUrl = `/admin/insights/asset_level_file_uploads/${id}/progress_bar_counts?${queryParams}`;

    fetch(fetchProgressUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        if (data) {
          this.updateProgressBar(targetCollection, data);
        }

        if (data?.running) {
          setTimeout(() => this.fetchProgressBarValues(targetCollection, params), 5000);
        }
      })
      .catch((error) => {
        // log error somewhere
      });
  }

  updateProgressBar(targetCollection, progressBarResponse) {
    if (targetCollection) {
      const elements = this[targetCollection];
      const failedDiv = elements.getElementsByClassName("failed")[0];
      const erroredDiv = elements.getElementsByClassName("errored")[0];
      const processingDiv = elements.getElementsByClassName("processing")[0];
      const successfulDiv = elements.getElementsByClassName("successful")[0];

      failedDiv.style.width = `${progressBarResponse.failed.percentage}%`;
      failedDiv.innerHTML = progressBarResponse.failed.count;
      failedDiv.dataset.bsOriginalTitle = `${progressBarResponse.failed.count} Failed`;

      erroredDiv.style.width = `${progressBarResponse.errored.percentage}%`;
      erroredDiv.innerHTML = progressBarResponse.errored.count;
      erroredDiv.dataset.bsOriginalTitle = `${progressBarResponse.errored.count} Errored`;

      processingDiv.style.width = `${progressBarResponse.processing.percentage}%`;
      processingDiv.innerHTML = progressBarResponse.processing.count;
      processingDiv.dataset.bsOriginalTitle = `${progressBarResponse.processing.count} Processing`;

      successfulDiv.style.width = `${progressBarResponse.successful.percentage}%`;
      successfulDiv.innerHTML = progressBarResponse.successful.count;
      successfulDiv.dataset.bsOriginalTitle = `${progressBarResponse.successful.count} Successful`;
    }
  }

  batchSetUp(targetCollection) {
    const targetCollectionTarget = this[targetCollection];
    if (targetCollectionTarget) {
      const elements = targetCollectionTarget;

      const failedDiv = elements.getElementsByClassName("failed")[0];
      const failedWidthPercentage = failedDiv.getAttribute("data-progress-bar-failed-percentage");
      const failedCount = failedDiv.getAttribute("data-progress-bar-failed-count");
      failedDiv.style.width = `${failedWidthPercentage}%`;
      failedDiv.dataset.bsOriginalTitle = `${failedCount} Failed`;

      const erroredDiv = elements.getElementsByClassName("errored")[0];
      const erroredWidthPercentage = erroredDiv.getAttribute(
        "data-progress-bar-errored-percentage",
      );
      const erroredCount = erroredDiv.getAttribute("data-progress-bar-errored-count");
      erroredDiv.style.width = `${erroredWidthPercentage}%`;
      erroredDiv.dataset.bsOriginalTitle = `${erroredCount} Errored`;

      const processingDiv = elements.getElementsByClassName("processing")[0];
      const processingWidthPercentage = processingDiv.getAttribute(
        "data-progress-bar-processing-percentage",
      );
      const processingCount = processingDiv.getAttribute("data-progress-bar-processing-count");
      processingDiv.style.width = `${processingWidthPercentage}%`;
      processingDiv.dataset.bsOriginalTitle = `${processingCount} Processing`;

      const successfulDiv = elements.getElementsByClassName("successful")[0];
      const successfulWidthPercentage = successfulDiv.getAttribute(
        "data-progress-bar-successful-percentage",
      );
      const successfulCount = successfulDiv.getAttribute("data-progress-bar-successful-count");
      successfulDiv.style.width = `${successfulWidthPercentage}%`;
      successfulDiv.dataset.bsOriginalTitle = `${successfulCount} Successful`;
    }
  }

  connect() {
    this.setup();
  }

  disconnect() {
    this.clearTimeouts();
  }

  clearTimeouts() {
    const highestId = window.setTimeout(() => {
      for (let i = highestId; i >= 0; i--) {
        window.clearInterval(i);
      }
    }, 0);
  }
}
