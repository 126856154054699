import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

enum ENDPOINTS {
  buildings = "/admin/listed_real_estate/snapshot_companies/{ID}/snapshot_buildings/search?q={QUERY}",
  companies = "/admin/listed_real_estate/snapshots/{ID}/snapshot_companies/search?q={QUERY}",
}
export default class extends Controller {
  static values = {
    url: { type: String, default: "" },
    endpoint: { type: String, default: "buildings" },
    id: { type: Number },
  };

  declare urlValue: string;
  declare readonly hasUrlValue: boolean;

  declare readonly hasEndpointValue: boolean;
  declare endpointValue: string | null;

  // To use for the snapshot_id, company_id, etc.
  declare idValue: number | null;
  declare hasIdValue: boolean;
  declare slimselect: SlimSelect;

  connect(): void {
    this.setup();
  }

  disconnect(): void {
    this.cleanupSelector();
  }

  private setup(): void {
    this.cleanupSelector();
    this.setSearchUrl();
    if (!this.element) {
      return;
    }

    type HeadersMap = Record<string, string>;
    const _headers: HeadersMap = { "Content-Type": "application/json" };

    const _ajax = (query: string) => {
      if (query === "" || query == null) {
        return [];
      }
      return new Promise((resolve, reject) => {
        const validateResponse = (response) => {
          if (!response.ok) {
            reject(new Error("No results found"));
          }
          return response.json();
        };

        // Ensure someone isn't trying a query with a string like `     a      `
        const cleanedQuery: string = query.trim();
        // Require at least 3 characters to do an ajax lookup
        if (cleanedQuery.length < 3) {
          reject(new Error("Search must be at least 2 characters"));
        }
        const queryUrl = this.urlValue.replace("{QUERY}", encodeURIComponent(cleanedQuery));
        fetch(queryUrl, { method: "GET", headers: _headers })
          .then(validateResponse)
          .then((data) => {
            if (!data) {
              reject(new Error("No results found"));
            }

            // the shape of the data required for slimselect to convert to dropdown entries
            const results = data?.map((result: unknown) => {
              const typedResult = result as { name: string; id: number };
              return {
                text: typedResult.name,
                value: typedResult.id,
              };
            });
            resolve(results);
          })
          .catch(<T>(error: T) => {
            reject(error);
          });
      });
    };

    interface OptionsMap {
      select: Element;
      events: {
        search: (query: string) => Promise<unknown>;
      };
    }
    const options: OptionsMap = {
      select: this.element,
      events: {
        search: (query: string) => Promise.resolve(_ajax(query)),
      },
    };
    this.slimselect = new SlimSelect(options);
  }

  private cleanupSelector(): void {
    if (!this.slimselect) {
      return;
    }
    this.slimselect.destroy();
    this.slimselect = undefined;
  }

  private setSearchUrl(): void {
    if (this.hasEndpointValue && Object.keys(ENDPOINTS).includes(this.endpointValue)) {
      this.urlValue = ENDPOINTS[this.endpointValue];
    } else {
      this.urlValue = ENDPOINTS.buildings;
    }

    if (this.hasIdValue) {
      this.urlValue = this.urlValue.replace("{ID}", this.idValue.toString());
    }
  }
}
