import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="isin-form"
export default class extends Controller {
  static targets = ["form", "checkbox"];
  declare readonly hasFormTarget: boolean;
  declare readonly formTarget: HTMLFormElement | null;

  toggle(): void {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit();
    }
  }
}
