// Filter out all the elements that are null, undefined, Nan, or empty strings
// @example
// const arr = [1,2, null, "foobar", undefined, NaN, null]
// compactBlank(arr) // [1, 2, "foobar"]
export function compactBlank<T>(array: T[]): T[] {
  if (!array) {
    return [];
  }

  return array.filter((x) => !!x);
}
