import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carbonEstimateTimePeriods", "energyEstimateTimePeriods"];
  declare readonly hasCarbonEstimateTimePeriodsTarget: boolean;
  declare readonly hasEnergyEstimateTimePeriodsTarget: boolean;
  declare readonly carbonEstimateTimePeriodsTarget: Element;
  declare readonly energyEstimateTimePeriodsTarget: Element;

  toggleCarbonEstimateFields(): void {
    if (this.hasCarbonEstimateTimePeriodsTarget) {
      this.toggleState(this.carbonEstimateTimePeriodsTarget);
    }
  }

  toggleEnergyEstimateFields(): void {
    if (this.hasEnergyEstimateTimePeriodsTarget) {
      this.toggleState(this.energyEstimateTimePeriodsTarget);
    }
  }

  private toggleState(timePeriodTarget: Element): void {
    if (timePeriodTarget.classList.contains("disabled")) {
      this.enableFields(timePeriodTarget);
    } else {
      this.disableFields(timePeriodTarget);
    }
  }

  private disableFields(timePeriodTarget: Element): void {
    timePeriodTarget.classList.add("disabled");
    const inputs: HTMLInputElement[] = Array.from(timePeriodTarget.getElementsByTagName("input"));
    const labels: HTMLElement[] = Array.from(timePeriodTarget.getElementsByTagName("label"));
    for (const element of inputs) {
      element.disabled = true;
    }
    for (const element of labels) {
      element.classList.add("disabled");
    }
  }

  private enableFields(timePeriodTarget: Element): void {
    timePeriodTarget.classList.remove("disabled");
    const inputs: HTMLInputElement[] = Array.from(timePeriodTarget.getElementsByTagName("input"));
    const labels: HTMLElement[] = Array.from(timePeriodTarget.getElementsByTagName("label"));
    for (const element of inputs) {
      element.disabled = false;
    }
    for (const element of labels) {
      element.classList.remove("disabled");
    }
  }
}
