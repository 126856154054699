import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static values = {
    options: { type: Object, default: {} },
    prefetchUrl: { type: String, default: undefined },
  };

  declare slimselect: SlimSelect;
  declare optionsValue: string[];
  declare readonly hasOptionsValue: boolean;
  declare readonly prefetchUrlValue: string;
  declare readonly hasPrefetchUrlValue: boolean;

  declare eventOptions: Record<string, unknown>; // used to set the beforeOpen event
  declare alreadyPreloaded: boolean; // used to prevent multiple preloads

  connect(): void {
    this.alreadyPreloaded = false;
    this.eventOptions = {};
    this.setEventOptions();

    this.slimselect = new SlimSelect({
      select: this.element,
      ...this.optionsValue,
      ...this.eventOptions,
    });
  }

  disconnect(): void {
    this.slimselect.destroy();
    this.slimselect = undefined;
  }

  // if a prefetchUrl is set, we will set the beforeOpen event to preload the data
  private setEventOptions(): void {
    if (this.hasPrefetchUrlValue) {
      this.eventOptions = {
        events: {
          beforeOpen: () => {
            this.preloadData();
          },
        },
      };
    }
  }

  private preloadData(): void {
    if (this.alreadyPreloaded) {
      return;
    }

    fetch(this.prefetchUrlValue, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.alreadyPreloaded = true;
        this.slimselect.setData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
