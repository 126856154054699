function addressLookup() {
  this.addressSubmitButton = $("#address_submission_button");
  this.addressInput = $("#address");

  // https://developers.google.com/maps/documentation/javascript/supported_types
  // https://stackoverflow.com/questions/8282026/how-to-limit-google-autocomplete-results-to-city-and-country-only
  // https://developers.google.com/maps/documentation/javascript/supported_types#table3
  this.googleOptions = {
    // address: instructs the Place Autocomplete service to return only geocoding results with a precise address
    // So a location like "France" should not be able to be specified
    types: ["address"],
  };

  this.coordinates = {
    latitude: null,
    longitude: null,
  };

  /**
   * Used to set the state of the submit button
   * @param {Boolean} state - If the submit button should be disabled or not
   * @return {Boolean}
   */
  function disableSubmitButton(state) {
    if (this.addressSubmitButton) {
      this.addressSubmitButton.prop("disabled", state);
      return state;
    }
  }

  /**
   * Setup event listener for keystrokes on the address field
   * @return {void}
   */
  this.initialize = function () {
    setAddressFieldValues({});
    // To ensure the submit button is disabled until a valid address has been selected
    this.addressInput.keyup((e) => {
      const allowedKeycodes = [13, 27];
      // if a user is typing in the address field disable the submit button
      // this is to prevent allow submission of partial addresses
      // allowedKeycodes is to prevent disabling if the user hits enter or ESC
      // 13 -> is enter and fired off when the select an address with the keyboard
      // 27 -> Escape (as in the user escaping from the menu after selecting a location)
      if (e.keyCode && !allowedKeycodes.includes(e.keyCode)) {
        disableSubmitButton(true);
      }
    });
    // By default the input should be disabled to prevent invalid address submissions
    disableSubmitButton(true);
  };

  /**
   * Setup event listener for google Autocomplete (Places)
   * @return {void}
   */
  this.setupGoogleAutocomplete = function () {
    const addressInput = $("input#address");
    if (addressInput.length === 0) {
      return null;
    }
    const addressField = addressInput[0];
    if (!addressField || !this.addressInput) {
      return;
    }

    // list of place types that are usable and allowed
    const allowedPlaceTypes = [
      "premise",
      "street_address",
      "plus_code",
      "sublocality_level_3",
      "subpremise",
      "university",
    ];
    // https://googlemaps.github.io/google-maps-services-java/v0.1.3/javadoc/com/google/maps/model/AddressType.html
    // https://developers.google.com/maps/documentation/places/web-service/supported_types

    if (typeof addressField !== "undefined") {
      const places = new google.maps.places.Autocomplete(addressField, this.googleOptions);
      google.maps.event.addListener(places, "place_changed", () => {
        const place = places.getPlace();
        const formattedAddress = place.formatted_address;
        const geometry = place.geometry; // Geometry is to ensure the address has valid coordinates
        const addressComponents = place.address_components; // Used to populate address components for the ClimateScan
        const name = place.name;

        // an imprecise location which does not contain a valid address should not be submittable
        // locations can have more than 1 distinguished place type and why this is mapped
        const preciseLocation = place.types.some((placeType) => {
          return allowedPlaceTypes.includes(placeType.toLowerCase());
        });

        if (!geometry || !addressComponents || !preciseLocation) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          // This means they entered an invalid on incomplete
          console.warn(`You entered an invalid place/location: ${place.name}`);
          disableSubmitButton(true);
          setAddressFieldValues({}); // clear all hidden input values
          clearCoordinates();
          setFormattedAddress("");

          if (!preciseLocation) {
            toggleImpreciseNotice(true);
          }
        } else {
          const coordinates = {
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
          };
          setFormattedAddress(formattedAddress);
          setCoordinatesValues(coordinates);
          // set all hidden input field values with the address info
          gatherAndSetAddressAttributes(addressComponents, name);
          toggleImpreciseNotice(false);
          // To ensure it doesn't get caught by the keyup event listener
          setTimeout(() => {
            addressField.value = formattedAddress;
            disableSubmitButton(false);
          }, 250);
        }
      });
    }
  };

  /*
   * Clear all the current addresses coordinates
   * @return {void}
   */
  function clearCoordinates() {
    // clear the current coordinates
    const emptyCoordinates = {
      latitude: null,
      longitude: null,
    };
    setCoordinatesValues(emptyCoordinates);
  }

  /**
   * Set the input fields formatted_address returned from Google [to better support displaying international addresses]
   * @param {String} formattedAddress
   * @return {void}
   */
  function setFormattedAddress(formattedAddress) {
    $("#formatted_address")[0].value = formattedAddress.length > 0 ? formattedAddress : "";
  }

  /**
   * Assign the coordinates for the specified location
   * @param {Object} coordinates [latitude, longitude]
   * @return {void}
   */
  function setCoordinatesValues(coordinates) {
    this.coordinates = coordinates;
    // Clear coordinates reserved in the the input fields
    $("#latitude")[0].value = coordinates.latitude || "";
    $("#longitude")[0].value = coordinates.longitude || "";
  }

  /**
   * Build a hash of address components to fill the values for the hidden input fields
   * @param {Object} addressComponents - a list of all pieces of a an address after a lookup
   * @return {void}
   */
  function gatherAndSetAddressAttributes(addressComponents, name) {
    const addressHash = {
      address_line1: (this.determineStreetAddress(addressComponents) || name)?.trim(),
      address_line2: "",
      city: this.getCity(addressComponents),
      // this may need refined for administrative_area_level_1 -> 5 for locations outside of the US
      state_or_province: this.getAddressAttribute(
        addressComponents,
        "administrative_area_level_1",
      )?.trim(),
      postal_code: this.getAddressAttribute(addressComponents, "postal_code"),
      country: this.getAddressAttribute(addressComponents, "country"),
    };
    setAddressFieldValues(addressHash);
  }

  /**
   * Build a street address with a street_number and route/road
   * @param {Object} addressComponents - a list of all pieces of a an address after a lookup
   * @param {String} name - the geolocated locations named address returned
   * @return {String}
   */
  this.determineStreetAddress = function (addressComponents) {
    // grab all possible attributes for the street address
    const streetNumber = this.getStreetNumber(addressComponents);
    const route = this.getRoute(addressComponents);
    if (streetNumber && route) {
      return [streetNumber, route].join(" ");
    }

    return "";
  };

  /**
   * Get the city name from the address components
   * @param {*} addressComponents
   * @returns
   */
  this.getCity = function (addressComponents) {
    const cityLabels = ["locality", "postal_town", "neighborhood", "administrative_area_level_3"];
    const city = this.fetchAndFilterAddressComponents(addressComponents, cityLabels);
    if (city.length > 0) {
      return city[0]?.trim();
    }
    return "";
  };

  /**
   * Get the street number from the address components
   * @param {*} addressComponents
   * @returns
   */
  this.getStreetNumber = function (addressComponents) {
    const streetAddressLabels = ["street_number", "plus_code", "premise", "sublocality_level_3"];
    const streetAddress = this.fetchAndFilterAddressComponents(
      addressComponents,
      streetAddressLabels,
    );
    if (streetAddress.length > 0) {
      return streetAddress[0]?.trim();
    }
    return "";
  };

  /**
   * Get the route from the address components
   * @param {*} addressComponents
   * @returns
   */
  this.getRoute = function (addressComponents) {
    const routeLabels = ["route", "sublocality_level_2"];
    const route = this.fetchAndFilterAddressComponents(addressComponents, routeLabels);

    if (route.length > 0) {
      return route[0]?.trim();
    }
    return "";
  };

  /**
   * Fetch and filter out all the empty component values
   * @param {Object} addressComponents - a list of all pieces of a an address after a lookup
   * @param {Array} attributeNames - a list of all the attribute names to filter out
   */
  this.fetchAndFilterAddressComponents = (addressComponents, attributeNames) => {
    if (!addressComponents || !attributeNames) {
      return [];
    }

    const attributeValues = [];
    attributeNames.forEach((attr, i) => {
      const addressAttribute = addressComponents.filter((comp) => comp.types.includes(attr));
      if (
        addressAttribute === "undefined" ||
        addressAttribute.length === 0 ||
        addressAttribute[0]?.short_name === ""
      ) {
        return [];
      }
      attributeValues.push(addressAttribute[0]?.short_name);
    });
    return attributeValues;
  };

  /**
   * Get a specific attribute based on its nested types array
   * example: { { types: ['foo'] }, { types: ['bar'] } }
   * @param {Object} addressComponents - a list of all pieces of a an address after a lookup
   * @param {String} attr - the attribute label value associated with the address component
   * @return {String}
   */
  this.getAddressAttribute = (addressComponents, attr) => {
    if (!addressComponents || !attr) {
      return "";
    }

    try {
      const addressAttribute = addressComponents.filter((comp) => comp.types.includes(attr));
      if (addressAttribute === "undefined" || addressAttribute.length === 0) {
        return "";
      }
      return addressAttribute[0]?.short_name;
    } catch (err) {
      return "";
    }
  };

  /**
   * Update all the hidden input field values after a lookup
   * @param {Object} address - a list of all pieces of a an address after a lookup
   * @return {void}
   */
  function setAddressFieldValues(address) {
    const addressLine1 = $("input[type=hidden]#address_line1");
    const addressLine2 = $("input[type=hidden]#address_line2");
    const city = $("input[type=hidden]#city");
    const stateOrProvince = $("input[type=hidden]#state_or_province");
    const postalCode = $("input[type=hidden]#postal_code");
    const country = $("input[type=hidden]#country");

    if (addressLine1[0]) {
      addressLine1[0].value = address?.address_line1 || "";
    }
    if (addressLine2[0]) {
      addressLine2[0].value = address?.address_line2 || "";
    }
    if (city[0]) {
      city[0].value = address?.city || "";
    }
    if (stateOrProvince[0]) {
      stateOrProvince[0].value = address?.state_or_province || "";
    }
    if (postalCode[0]) {
      postalCode[0].value = address?.postal_code || "";
    }
    if (country[0]) {
      country[0].value = address?.country || "";
    }
  }

  function toggleImpreciseNotice(show) {
    const addressError = $("#address-error")[0];

    if (show === true) {
      addressError.classList.remove("d-none");
      addressError.innerHTML = "A valid street address is required.";
    } else {
      addressError.classList.add("d-none");
      addressError.innerHTML = "";
    }
  }

  this.initialize();
  this.setupGoogleAutocomplete();
}

window.addressLookup = addressLookup;
