import { Controller } from "@hotwired/stimulus";

enum AreaUnit {
  sqm = "sq m",
  sqft = "sq ft",
}

enum AreaUnitValue {
  sqm = 929022,
  sqft = 10000000,
}

enum AreaUnitNotice {
  sqm = "Please enter a building under 929,022 Square Meters",
  sqft = "Please enter a building under 10 Million Square Feet",
}

export default class extends Controller {
  static targets = ["input", "notice"];
  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLInputElement | HTMLElement;

  declare readonly hasNoticeTarget: boolean;
  declare readonly noticeTarget: HTMLSpanElement | HTMLElement;

  connect(): void {}

  disconnect(): void {}

  change(e: Event): void {
    if (this.hasInputTarget && this.hasNoticeTarget) {
      if ((e.target as HTMLInputElement).value === AreaUnit.sqm) {
        this.noticeTarget.setAttribute("aria-label", AreaUnitNotice.sqm);
        this.noticeTarget.setAttribute("data-bs-original-title", AreaUnitNotice.sqm);
        this.inputTarget.setAttribute("max", AreaUnitValue.sqm.toString());
      } else {
        this.noticeTarget.setAttribute("aria-label", AreaUnitNotice.sqft);
        this.noticeTarget.setAttribute("data-bs-original-title", AreaUnitNotice.sqft);
        this.inputTarget.setAttribute("max", AreaUnitValue.sqft.toString());
      }
    }
  }
}
