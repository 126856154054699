import { Application } from "@hotwired/stimulus";

import SlimselectController from "./slimselect_controller";

import CompaniesManualAttributeController from "./companies/manual_attribute_controller";
import DiligenceAppLookupController from "./diligence/app_lookup_controller";
import CrremPathwayDropdownController from "./diligence/crrem/pathway_dropdown_controller";
import DiligenceGrossFloorAreaController from "./diligence/gross_floor_area_controller";
import DiligenceYearBuiltController from "./diligence/year_built_controller";
import AssetLevelFileUploadController from "./insights/asset_level_file_upload_controller";
import ModalController from "./modal_controller";
import ProgressBarController from "./progress_bar_controller";
import SnapshotSearch from "./snapshots/search_controller";
import SnpCompanyController from "./snp_company_controller";
import ToggleController from "./toggle_controller"; // to Display/Hide a hidden block on demand
import ToggleFtseController from "./toggle_ftse_controller";
import VarianceReportUpload from "./variance_report_upload_controller";

// Components JS controllers
import DiligenceBenchmarkChartController from "./diligence/benchmark/chart_controller";
import PathwayController from "./diligence/pathway_controller";

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

export { application };
application.register("slimselect", SlimselectController);
application.register("diligence-app-lookup", DiligenceAppLookupController);
application.register("diligence-benchmark-chart", DiligenceBenchmarkChartController);
application.register("diligence-gross-floor-area", DiligenceGrossFloorAreaController);
application.register("diligence-year-built", DiligenceYearBuiltController);
application.register("companies-attribute", CompaniesManualAttributeController);
application.register("ftse", ToggleFtseController);
application.register("snp-company", SnpCompanyController);
application.register("variance-report-upload", VarianceReportUpload);
application.register("toggle", ToggleController);
application.register("snapshot-search", SnapshotSearch);
application.register("asset-level-file-upload", AssetLevelFileUploadController);
application.register("crrem-pathway-dropdown", CrremPathwayDropdownController);
application.register("progress-bar", ProgressBarController);
application.register("modal", ModalController);
// Load components JS controllers
application.register("crrem-pathway", PathwayController);
