import { Controller } from "@hotwired/stimulus";
import * as Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts);
import highchartsNoData from "highcharts/modules/no-data-to-display";
highchartsNoData(Highcharts);
import { titleize } from "../../src/utilities/strings";

// List of allowed units for the estimates to enforce enum types
enum EstimateUnits {
  // Carbon
  "kgCO₂e/ft²" = 0,
  "kgCO₂e/m²" = 1,
  "kgCO₂e" = 2,

  // Energy
  MWh = 3,
  kWh = 4,
  "kWh/ft²" = 5,
  "kWh/m²" = 6,

  // If no unit is provided
  "N/A" = 7,
}

interface ChartMetadataMap {
  estimateUnits: EstimateUnits;
  crremVersion: string;
}

enum EstimateTypes {
  carbon = 0,
  energy = 1,
}

interface chartSizesMap {
  beforeChartWidth: number;
  beforeChartHeight: number;

  // sized allowed for printing
  printWidth: number;
  printHeight: number;
}
export default class extends Controller {
  static targets = ["chart"];

  static values = {
    estimateType: { type: String, default: "" },
    // labels and other metadata for the chart
    chartMetadata: {
      type: Object,
      default: {
        estimateUnits: "kgCO₂e/m²",
        crremVersion: "2.05",
      },
    },
    years: { type: Array, default: [] },
    historicalPerformance: {
      type: Array,
      default: [null, null, null], // There should always be at least 3 historical performance values
    },
    crremProjections: { type: Array, default: [] },
    targetPathway: { type: Array, default: [] },
    carbonStrandingPoint: { type: Array, default: [] },
    carbonStrandingYear: { type: Number, default: null },
    carbonAlreadyStranded: { type: Boolean, default: false },
    carbonNeverStranded: { type: Boolean, default: false },
    noData: { type: String, default: "" }, // A data attribute to be used for the chart when there is no data
  };

  // Highcharts chart object
  declare chart: Highcharts.Chart;
  declare chartSizes: chartSizesMap;

  // Historical performance values (estimates)
  declare readonly historicalPerformanceValue: number[];
  declare readonly hasHistoricalPerformanceValue: boolean;

  // Future usage
  declare readonly crremProjectionsValue: number[];
  declare readonly hasCrremProjectionsValue: boolean;

  // targets/Pathway data
  declare readonly targetPathwayValue: number[];
  declare readonly hasTargetPathwayValue: boolean;

  // Stranding years
  declare readonly carbonStrandingPointValue: number[];
  declare readonly hasCarbonStrandingPointValue: boolean;
  declare readonly carbonStrandingYearValue: number;
  declare readonly hasCarbonStrandingYearValue: boolean;
  declare readonly carbonAlreadyStrandedValue: boolean;
  declare readonly hasCarbonAlreadyStrandedValue: boolean;
  declare readonly carbonNeverStrandedValue: boolean;
  declare readonly hasCarbonNeverStrandedValue: boolean;

  // Element targeted for rendering the chart to
  declare readonly chartTarget: HTMLDivElement | HTMLElement;
  declare readonly hasChartTarget: boolean;

  // values
  declare readonly estimateTypeValue: EstimateTypes;
  declare readonly hasEstimateTypeValue: boolean;
  declare readonly noDataValue: string;
  declare readonly hasNoDataValue: boolean;

  // Timespan of Estimates and projections to be mapped on the chart
  declare readonly yearsValue: number[];
  declare readonly hasYearsValue: boolean;
  declare readonly chartMetadataValue: ChartMetadataMap;

  connect(): void {
    // Initialize chartSizes with default values
    this.chartSizes = {
      beforeChartWidth: 0,
      beforeChartHeight: 0,
      printWidth: 965,
      printHeight: 450,
    };

    // Ensure all the required targets and values are present before rendering the chart
    if (this.hasRequiredChartValues()) {
      this.renderChart();

      // Add event listeners for beforeprint and afterprint events
      // In order to redraw the chart with the correct size for printing
      window.addEventListener("beforeprint", this.setPrintResolution.bind(this));
      window.addEventListener("afterprint", this.resetPrintResolution.bind(this));
    }
  }

  disconnect(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private hasRequiredChartValues(): boolean {
    return (
      this.hasChartTarget &&
      this.hasHistoricalPerformanceValue &&
      this.hasCrremProjectionsValue &&
      this.hasTargetPathwayValue
    );
  }

  private setPrintResolution(): void {
    if (this.chart) {
      // set this default values to the current chart size, so they can be restored after printing
      this.chartSizes.beforeChartWidth = this.chart.chartWidth;
      this.chartSizes.beforeChartHeight = this.chart.chartHeight;
      this.chart.setSize(this.chartSizes.printWidth, this.chartSizes.printHeight, false);
      this.redrawChart();
    }
  }

  private resetPrintResolution(): void {
    if (this.chart) {
      this.chart.setSize(
        this.chartSizes.beforeChartWidth,
        this.chartSizes.beforeChartHeight,
        false,
      );
      this.redrawChart();
    }
  }

  // Redraw the chart when the window size is changed
  // this should generally happen when the report is printed
  private redrawChart(): void {
    if (this.chart) {
      this.chart.reflow();
      this.chart.redraw();
    }
  }

  private renderChart(): void {
    // Render the chart
    this.chart = Highcharts.chart(this.chartTarget, {
      title: "",

      exporting: {
        enabled: false,
      },
      lang: {
        noData: this.noDataValue,
      },
      credits: {
        enabled: false,
      },
      chart: {
        height: "450px",
        type: "spline",
      },
      legend: this.chartLegend(),
      tooltip: {
        pointFormat:
          '<strong style="color: {series.color}">{series.dashStyle} {series.name}: </strong>{point.y}<br/>',
        valueSuffix: this.chartMetadataValue.estimateUnits.toString(),
        shared: true,
        usedHTML: true,
      },
      responsive: this.chartResponsive(),
      noData: {
        style: {
          color: "#6c757d",
          fontFamily: "Verb",
          fontSize: "18px",
          fontWeight: "bold",
        },
      },

      yAxis: {
        allowDecimals: false,
        title: {
          text: this.chartMetadataValue.estimateUnits,
          style: {
            fontFamily: "Verb",
            fontWeight: "bold",
          },
        },
      },
      xAxis: {
        allowDecimals: false,
        crosshair: true,
        accessibility: {
          rangeDescription: `Range: Historical estimates and future projections from ${this.yearsValue[0].toString()} to ${this.yearsValue[this.yearsValue.length - 1].toString()}`,
        },
        categories: this.yearsValue,
        tickInterval: this.plotInterval(),
        labels: {
          enabled: true,
        },
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          label: {
            enabled: false,
            connectorAllowed: false,
          },
        },
      },
      series: this.chartSeriesData(),
    });

    if (this.hasNoDataValue) {
      this.chart?.showNoData(this.noDataValue);
    }
  }

  // Used to determine the interval for the x-axis labels
  // based on how many years are being plotted
  private plotInterval(): number {
    const interval = this.yearsValue.length;
    switch (true) {
      case interval <= 5: // when 2045-2050
        return 1;
      case interval <= 10: // when 2040-2045
        return 2;
      default: // when the year the scan is/was created is < 2040
        return 5;
    }
  }

  private chartSeriesData(): object {
    const chartSeriesData = [
      {
        // Historical performance data (how much has been used/produced in the past)
        name: "Historical Performance",
        data: this.historicalPerformanceValue,
        color: "#2DB892",
        dashStyle: "solid",
        type: "spline",
        marker: {
          enabled: false,
        },
      },
      {
        // Pathway target data to get to NET ZERO
        name: `CRREM [${this.chartMetadataValue.crremVersion}, 1.5°C]`,
        data: this.targetPathwayValue,
        color: "#32a4e6",
        dashStyle: "shortdash",
        type: "spline",
        marker: {
          enabled: false,
        },
      },
      {
        // Future usage projection data
        name: "Status Quo",
        data: this.crremProjectionsValue,
        color: "#F48231",
        dashStyle: "shortdash",
        type: "spline",
        marker: {
          enabled: false,
        },
      },
      {
        // Stranding year data
        name: this.carbonStrandingYearDisplay(),
        data: this.carbonStrandingPointValue,
        color: "#F48231",
        dashStyle: "none",
        type: "scatter",
        marker: {
          fillColor: "#FFFFFF",
          lineColor: "#F48231",
          lineWidth: 3,
          symbol: "circle",
          radius: 6,
          enabled: true,
        },
      },
    ];
    if (String(this.estimateTypeValue) !== "Carbon") {
      chartSeriesData.pop(); // Remove the stranding point data unless this is a carbon chart
    }
    return chartSeriesData;
  }

  private carbonStrandingYearDisplay(): string {
    if (this.hasCarbonNeverStrandedValue && this.carbonNeverStrandedValue) {
      return "Stranding Year - Never Stranded";
    }
    if (this.hasCarbonAlreadyStrandedValue && this.carbonAlreadyStrandedValue) {
      return "Stranding Year - Already Stranded";
    }
    if (this.hasCarbonStrandingYearValue) {
      return `Stranding Year - ${this.carbonStrandingYearValue}`;
    }
    return "Stranding Year";
  }

  private chartLegend(): object {
    return {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      symbolPadding: 5,
      itemDistance: 18,
      itemStyle: {
        fontFamily: "Verb",
        fontWeight: "Bold",
      },
    };
  }

  // JSON object for the chart responsive rules
  private chartResponsive(): object {
    return {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: "center",
              layout: "horizontal",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    };
  }
}
