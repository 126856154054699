import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

export default class extends Controller {
  static targets = ["location", "propertyType"];
  static values = {
    id: { type: String, default: "" }, // This is the UUID for the estimate
    estimateType: { type: String, default: "Carbon" }, // This is the type of estimate, ie. Carbon or Energy
    location: { type: String, default: "" }, // This is the CRREM location to be used for the chart
    url: { type: String, default: "" }, // This is the URL to fetch the new chart data, (admin and/or customer)
  };

  // targets
  declare readonly locationTarget: HTMLSelectElement;
  declare readonly hasLocationTarget: boolean;
  declare readonly propertyTypeTarget: HTMLSelectElement;
  declare readonly hasPropertyTypeTarget: boolean;

  // values
  declare readonly idValue: string;
  declare readonly hasIdValue: boolean;

  declare readonly estimateTypeValue: string;
  declare readonly hasEstimateTypeValue: boolean;

  declare readonly urlValue: string;
  declare readonly hasUrlValue: boolean;

  connect(): void {
    // Setup throttling and debouncing for the fetchNewPathwayChart method
    // So the method is not called too often
    this.fetchNewPathwayChart = debounce(this.fetchNewPathwayChart, 500).bind(this);
    this.fetchNewPathwayChart = throttle(this.fetchNewPathwayChart, 1000).bind(this);
  }

  loadNewDataWithParams(event: Event): void {
    event.preventDefault();

    if (
      this.hasIdValue &&
      this.hasEstimateTypeValue &&
      this.hasLocationTarget &&
      this.hasPropertyTypeTarget &&
      this.hasUrlValue &&
      this.locationTarget.value &&
      this.propertyTypeTarget.value
    ) {
      this.fetchNewPathwayChart();
    }
  }

  private async fetchNewPathwayChart(): Promise<void> {
    get(this.urlValue, {
      responseKind: "turbo-stream",
      query: {
        estimate_type: this.estimateTypeValue,
        location: this.locationTarget.value,
        property_type: this.propertyTypeTarget.value,
      },
    })
      .then(() => {})
      .catch(<T>(error: T) => {
        console.log("Error loading CRREM pathway chart changes.");
        console.error(error);
      });
  }
}
