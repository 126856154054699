import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["publishButton", "toggleButton", "form"];

  declare readonly hasFormTarget: boolean;
  declare readonly formTarget: HTMLFormElement | null;
  declare readonly hasPublishButtonTarget: boolean;
  declare readonly publishButtonTarget: HTMLFormElement | null;
  declare readonly hasToggleButtonTarget: boolean;
  declare readonly toggleButtonTarget: HTMLFormElement | null;

  connect() {}
  disconnect() {
    // Clean the form upload form and hide all the targets

    // Reset and hide the upload form
    if (this.hasFormTarget) {
      this.formTarget?.reset();
      this.formTarget.classList.add("d-none");
    }

    // Hide the toggle button
    if (this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.add("d-none");
    }

    // Hide the publish button
    if (this.hasPublishButtonTarget) {
      this.publishButtonTarget.classList.add("d-none");
    }
  }

  toggle(event: Event) {
    event.preventDefault();

    // Hide/display the upload form
    if (this.hasFormTarget) {
      // Clean the form upload files
      this.formTarget?.reset();
      this.formTarget.classList.toggle("d-none");
    }

    // Hide/display the toggle button depending on the state for the forms current visibility
    if (this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.toggle("d-none");
    }

    // Hide/display the publish button depending on the state for the forms current visibility
    if (this.hasPublishButtonTarget) {
      this.publishButtonTarget.classList.toggle("d-none");
    }
  }
}
