import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect(): void {}

  disconnect(): void {}

  change(e: KeyboardEvent): undefined | boolean {
    // Prevent certain characters from being entered into the field (that number fields allow by default)
    // 190 => .
    // 189 => -
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
      return false;
    }

    const valueLength = (this.element as HTMLInputElement).value.length;

    // Prevent the user from adding any additional characters if the year field is already 4 characters long
    if ((e.key >= "0" && e.key <= "9") || (e.key >= "NumPad0" && e.key <= "NumPad9")) {
      if (valueLength >= 4) {
        e.preventDefault();
        return false;
      }
    }
  }

  afterChange(e: Event): undefined | boolean {
    const valueLength = (this.element as HTMLInputElement).value.length;
    const inputValue = (this.element as HTMLInputElement).value;

    // if a user does something like paste a value into the field, only allow the first 4 characters to be pasted
    if (valueLength > 4) {
      (e.target as HTMLInputElement).value = inputValue.substring(0, 4);
      e.preventDefault();
      return false;
    }
  }
}
