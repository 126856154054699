import { Controller } from "@hotwired/stimulus";
import * as Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts);
import highchartsNoData from "highcharts/modules/no-data-to-display";
highchartsNoData(Highcharts);
import { compactBlank } from "../../../src/utilities/arrays";
import { isEmpty } from "../../../src/utilities/objects";

interface chartSizesMap {
  beforeChartWidth: number;
  beforeChartHeight: number;

  // sized allowed for printing
  printWidth: number;
  printHeight: number;
}

// List of allowed units for the estimates to enforce enum types
enum EstimateUnits {
  // Carbon
  "kgCO₂e/ft²" = "kgCO₂e/ft²",
  "kgCO₂e/m²" = "kgCO₂e/m²",
  "kgCO₂e" = "kgCO₂e",

  // Energy
  MWh = "MWh",
  kWh = "kWh",
  "kWh/ft²" = "kWh/ft²",
  "kWh/m²" = "kWh/m²",

  // If no unit is provided
  "N/A" = "N/A",
}
// List of allowed types for the estimate types
enum EstimateTypes {
  carbon = "carbon",
  energy = "energy",
}

// Interface for the metadata of the chart
interface ChartMetadataMap {
  estimateType: EstimateTypes;
  estimateUnits: EstimateUnits;
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

export default class extends Controller {
  static targets = ["chart"];
  static values = {
    metadata: {
      type: Object,
      default: {
        title: "",
        estimateType: "energy",
        estimateUnits: "kWh/ft²",
        xAxisTitle: "",
        yAxisTitle: "",
      },
    },
    timePeriods: { type: Array, default: [] }, // Array of years to be displayed in the chart

    // Benchmark and estimate data
    omitBenchmark: { type: Boolean, default: false }, // Flag to omit benchmark data
    annualIntensityLabel: { type: String, default: "" }, // Label for the annual intensity values
    annualIntensity: { type: Array, default: [] }, // Array of annual intensity values
    globalBenchmark: { type: Array, default: [] }, // Array of global benchmark values

    countryBenchmarkLabel: { type: String, default: "" }, // Label for the country benchmark values
    countryBenchmark: { type: Array, default: [] }, // Array of country benchmark values
    countrySubregionBenchmarkLabel: { type: String, default: "" }, // Label for the country subregion benchmark values
    countrySubregionBenchmark: { type: Array, default: [] }, // Country subregion for the benchmark data
    continentBenchmarkLabel: { type: String, default: "" }, // Label for the continent benchmark values
    continentBenchmark: { type: Array, default: [] }, // Array of continent benchmark values
    stateProvinceBenchmarkLabel: { type: String, default: "" }, // Label for the state/province benchmark values
    stateProvinceBenchmark: { type: Array, default: [] }, // Array of state/province benchmark
    continentSubregionBenchmarkLabel: { type: String, default: "" }, // Label for the continent subregion benchmark values
    continentSubregionBenchmark: { type: Array, default: [] }, // Array of continent subregion benchmark
  };

  // / Highcharts chart object
  declare chart: Highcharts.Chart;
  declare chartSizes: chartSizesMap;

  //////////////////////////////////////////////////
  // Targets
  //////////////////////////////////////////////////
  // Element targeted for rendering the chart to
  declare readonly chartTarget: HTMLDivElement | HTMLElement;
  declare readonly hasChartTarget: boolean;

  //////////////////////////////////////////////////
  // Values
  //////////////////////////////////////////////////
  // declare readonly estimateTypeValue: EstimateTypes;
  // declare readonly hasEstimateTypeValue: boolean;
  // declare readonly estimateUnitsValue: EstimateUnits;
  // declare readonly hasEstimateUnitsValue: boolean;
  declare readonly metadataValue: ChartMetadataMap;
  declare readonly hasMetadataValue: boolean;
  declare readonly timePeriodsValue: (string | number)[];
  declare readonly hasTimePeriodsValue: boolean;

  // benchmark and estimate data
  declare readonly omitBenchmarkValue: boolean;
  declare readonly hasOmitBenchmarkValue: boolean;
  // Annual intensity data
  declare readonly annualIntensityLabelValue: string;
  declare readonly hasAnnualIntensityLabel: boolean;
  declare readonly annualIntensityValue: number[];
  declare readonly hasAnnualIntensityValue: boolean;
  // Global Benchmark data
  declare readonly globalBenchmarkValue: number[];
  declare readonly hasGlobalBenchmarkValue: boolean;
  // Country benchmark data
  declare readonly countryBenchmarkLabelValue: string;
  declare readonly countryBenchmarkValue: number[];
  declare readonly hasCountryBenchmarkValue: boolean;
  // country subregion benchmark data
  declare readonly countrySubregionBenchmarkLabelValue: string;
  declare readonly countrySubregionBenchmarkValue: number[];
  declare readonly hasCountrySubregionBenchmarkValue: boolean;
  // continent benchmark data
  declare readonly continentBenchmarkLabelValue: string;
  declare readonly continentBenchmarkValue: number[];
  declare readonly hasContinentBenchmarkValue: boolean;
  // state/province benchmark data
  declare readonly stateProvinceBenchmarkLabelValue: string;
  declare readonly stateProvinceBenchmarkValue: number[];
  declare readonly hasStateProvinceBenchmarkValue: boolean;
  // continent subregion benchmark data
  declare readonly continentSubregionBenchmarkLabelValue: string;
  declare readonly continentSubregionBenchmarkValue: number[];
  declare readonly hasContinentSubregionBenchmarkValue: boolean;

  connect(): void {
    // Initialize chartSizes with default values
    this.chartSizes = {
      beforeChartWidth: 0,
      beforeChartHeight: 0,
      printWidth: 965,
      printHeight: 450,
    };
    this.renderChart();

    if (this.chart) {
      // Add event listeners for beforeprint and afterprint events
      // In order to redraw the chart with the correct size for printing
      window.addEventListener("beforeprint", this.setPrintResolution.bind(this));
      window.addEventListener("afterprint", this.resetPrintResolution.bind(this));
    }
  }

  disconnect(): void {
    this.chart.destroy();
    this.chart = undefined;
  }

  private setPrintResolution(): void {
    if (this.chart) {
      // set this default values to the current chart size, so they can be restored after printing
      this.chartSizes.beforeChartWidth = this.chart.chartWidth;
      this.chartSizes.beforeChartHeight = this.chart.chartHeight;
      this.chart.setSize(this.chartSizes.printWidth, this.chartSizes.printHeight, false);
      this.redrawChart();
    }
  }

  private resetPrintResolution(): void {
    if (this.chart) {
      this.chart.setSize(
        this.chartSizes.beforeChartWidth,
        this.chartSizes.beforeChartHeight,
        false,
      );
      this.redrawChart();
    }
  }

  // Redraw the chart when the window size is changed
  // this should generally happen when the report is printed
  private redrawChart(): void {
    if (this.chart) {
      this.chart.reflow();
      this.chart.redraw();
    }
  }

  // Render the chart
  private renderChart(): void {
    this.chart = Highcharts.chart(this.element, {
      title: {
        text: this.metadataValue.title,
        style: {
          fontWeight: "500",
          fontFamily: "Verb",
        },
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        // height: (9 / 16 * 100) + '%' // 16:9 ratio
        // Keeping this as reference in case we want to go back to the previous size
        height: "450px",
        type: "line",
      },
      yAxis: {
        allowDecimals: false,
        minTickInterval: 1,
        title: {
          text: this.metadataValue.yAxisTitle,
          style: {
            fontWeight: "bold",
            fontFamily: "Verb",
          },
        },
      },
      xAxis: {
        allowDecimals: false,
        accessibility: {
          rangeDescription: this.metadataValue.xAxisTitle,
        },
        minTickInterval: 1,
        categories: this.timePeriodsValue,
        labels: {
          enabled: true,
        },
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          label: {
            enabled: false,
            connectorAllowed: false,
          },
          marker: {
            enabled: true,
          },
        },
      },
      tooltip: {
        formatter: function () {
          const plotLabel = `<span style="color:${this.color}">●</span>&nbsp;${this.series.name}: <strong>${Highcharts.numberFormat(this.y, 2)}</strong>`;
          return plotLabel;
        },
        shared: false,
      },
      series: this.seriesData(),
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        symbolPadding: 2,
        itemDistance: 8,
        itemStyle: {
          fontWeight: "Bold",
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                symbolPadding: 2,
                itemDistance: 8,
              },
            },
          },
        ],
      },
    });
  }

  // Filter out all the datasets where the benchmark data is not provided
  private seriesData(): object {
    const seriesData = [];
    if (this.hasAnnualIntensityValue) {
      const annualIntensitySeriesData = this.annualIntensitySeriesData();
      if (!isEmpty(annualIntensitySeriesData)) {
        seriesData.push(this.annualIntensitySeriesData());
      }
    }

    if (this.hasGlobalBenchmarkValue) {
      const globalSeriesData = this.globalSeriesData();
      if (!isEmpty(globalSeriesData)) {
        seriesData.push(this.globalSeriesData());
      }
    }

    if (this.hasContinentBenchmarkValue) {
      const continentSeriesData = this.continentSeriesData();
      if (!isEmpty(continentSeriesData)) {
        seriesData.push(this.continentSeriesData());
      }
    }

    if (this.hasContinentSubregionBenchmarkValue) {
      const continentSubregionSeriesData = this.continentSubregionSeriesData();
      if (!isEmpty(continentSubregionSeriesData)) {
        seriesData.push(this.continentSubregionSeriesData());
      }
    }

    if (this.hasCountryBenchmarkValue) {
      const countrySeriesData = this.countrySeriesData();
      if (!isEmpty(countrySeriesData)) {
        seriesData.push(this.countrySeriesData());
      }
    }

    if (this.hasCountrySubregionBenchmarkValue) {
      const countrySubregionSeriesData = this.countrySubregionSeriesData();
      if (!isEmpty(countrySubregionSeriesData)) {
        seriesData.push(this.countrySubregionSeriesData());
      }
    }

    if (this.hasStateProvinceBenchmarkValue) {
      const stateProvinceSeriesData = this.stateProvinceSeriesData();
      if (!isEmpty(stateProvinceSeriesData)) {
        seriesData.push(this.stateProvinceSeriesData());
      }
    }
    return seriesData;
  }

  // If no benchmarks are provided for the dataset
  // they are set as null, which can be a problem for the chart
  private hasBenchmarkData(arr: number[]): boolean {
    if (!arr) {
      return false;
    }

    const compactArray = compactBlank(arr);
    return compactArray.length > 0;
  }

  private annualIntensitySeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasAnnualIntensityValue ||
      !this.hasBenchmarkData(this.annualIntensityValue)
    ) {
      return {};
    }

    return {
      name: this.annualIntensityLabelValue,
      data: this.annualIntensityValue,
      color: "#2ab992",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private globalSeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasGlobalBenchmarkValue ||
      !this.hasBenchmarkData(this.globalBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: "Global Median",
      data: this.globalBenchmarkValue,
      dashStyle: "shortdot",
      color: "#2d8eb7",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private countrySeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasCountryBenchmarkValue ||
      !this.hasBenchmarkData(this.countryBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: this.countryBenchmarkLabelValue,
      data: this.countryBenchmarkValue,
      dashStyle: "shortdot",
      color: "#ffc74e",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private countrySubregionSeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasCountrySubregionBenchmarkValue ||
      !this.hasBenchmarkData(this.countrySubregionBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: this.countrySubregionBenchmarkLabelValue,
      data: this.countrySubregionBenchmarkValue,
      dashStyle: "shortdot",
      color: "#995716",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private continentSeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasContinentBenchmarkValue ||
      !this.hasBenchmarkData(this.continentBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: this.continentBenchmarkLabelValue,
      data: this.continentBenchmarkValue,
      dashStyle: "shortdot",
      color: "#c0c011",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private stateProvinceSeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.hasStateProvinceBenchmarkValue ||
      !this.hasBenchmarkData(this.stateProvinceBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: this.stateProvinceBenchmarkLabelValue,
      data: this.stateProvinceBenchmarkValue,
      dashStyle: "shortdot",
      color: "#53558e",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }

  private continentSubregionSeriesData(): object {
    if (
      this.omitBenchmarkValue === true ||
      !this.continentSubregionBenchmarkValue ||
      !this.hasBenchmarkData(this.continentSubregionBenchmarkValue)
    ) {
      return {};
    }

    return {
      name: this.continentSubregionBenchmarkLabelValue,
      data: this.continentSubregionBenchmarkValue,
      dashStyle: "shortdot",
      color: "#E59398",
      marker: {
        symbol: "circle",
      },
      pointPlacement: "on",
    };
  }
}
