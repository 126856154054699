import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

export default class extends Controller {
  static targets = ["error", "loading", "query", "results"];
  declare readonly hasErrorTarget: boolean;
  declare readonly errorTarget: HTMLDivElement | null;

  declare readonly hasLoadingTarget: boolean;
  declare readonly loadingTarget: HTMLDivElement | null;

  declare readonly hasQueryTarget: boolean;
  declare readonly queryTarget: HTMLInputElement | null;

  declare readonly hasResultsTarget: boolean;
  declare readonly resultsTarget: HTMLInputElement | null;

  connect(): void {
    this.getCompanyData = debounce(this.getCompanyData, 500).bind(this);
    this.getCompanyData = throttle(this.getCompanyData, 1000).bind(this);
  }

  disconnect(): void {
    this.clearError();
    this.toggleLoadingIndicator("hide");
  }

  submitForm(event: Event): void {
    event.preventDefault();

    // Cleanup the query string to remove leading and trailing whitespace and replace multiple spaces with a single space
    // Also, ensure that we have a query string to submit
    const queryString: string = this.cleanupQueryString(this.queryTarget.value);
    // ensure we have a query string to submit
    if (queryString.length >= 3) {
      this.clearError(); // clear any error messages before submitting the query
      this.clearResults(); // clear any previous results before submitting the query
      this.toggleLoadingIndicator("display");
      this.getCompanyData(queryString);
    } else {
      this.displayError("Query string is too short (min 3 characters)");
    }
  }

  private clearError(): void {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.add("d-none");
      this.errorTarget.innerHTML = "";
    }
  }

  private clearResults(): void {
    if (this.hasResultsTarget) {
      this.resultsTarget.innerHTML = "";
    }
  }

  private displayError<T>(error: T | string): void {
    if (this.hasErrorTarget) {
      const errorHTML = `<p class="text-danger">${error}</p>`;
      this.errorTarget.classList.remove("d-none");
      this.errorTarget.innerHTML = errorHTML;
    }
  }

  // Because the snowflake queries are so slow, we want to be able to toggle a loading indicator
  private toggleLoadingIndicator(state: string): void {
    if (this.hasLoadingTarget) {
      if (state === "display") {
        this.loadingTarget.classList.remove("d-none");
        if (this.hasResultsTarget) {
          this.resultsTarget.classList.add("d-none");
        }
      } else {
        this.loadingTarget.classList.add("d-none");
        if (this.hasResultsTarget) {
          this.resultsTarget.classList.remove("d-none");
        }
      }
    }
  }

  private async getCompanyData(queryString: string): Promise<void> {
    get("/admin/snp_companies/search", {
      responseKind: "turbo-stream",
      query: {
        "company[q]": queryString,
      },
    })
      .then(() => {
        this.toggleLoadingIndicator("hide");
      })
      .catch(<T>(error: T) => {
        this.toggleLoadingIndicator("hide");
        console.log("Error occurred when searching for SNP Company");
        if (error) {
          this.displayError(error);
        }
      });
  }

  private cleanupQueryString(queryString: string): string {
    if (!queryString || queryString.length === 0) {
      return "";
    }

    // Remove leading and trailing whitespace
    // Replace multiple spaces with a single space
    return queryString.toString().trim().replace(/\s+/g, " ");
  }
}
